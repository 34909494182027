import React from "react";
import "../Styles/Home.css";
import { COLORS } from "../Utils/COLORS";
import { useNavigate } from "react-router";

import { Helmet } from "react-helmet";
import HomeLayout from "../Components/Molecules/HomeLayout";
export default function Home() {
  const navigate = useNavigate();
  const buttonStyle = {
    background: COLORS.primary,
    color: "rgb(0,0,0)",
    borderColor: "#FFC107",
  };
  return (
    <div className="home-container">
      <div>
        <Helmet>
          <title>
            Create Online Store | Sell & Ship Products | Rebuilters{" "}
          </title>
          <meta
            name="description"
            content="Create your online store, sell your products, and ship your orders all in one place , Build your online shop quickly and easily with our intuitive platform which allows you to customize to fit your brand and start selling no time"
          />
        </Helmet>
      </div>
      <div className="home-background">
        <div className="content-wrapper">
          <span className="fw-bold fade-in">
            Join thousands of satisfied customers who trust us for their online
            presence
          </span>
        </div>
        <div className="" style={{ marginTop: "70px" }}>
          <div className="row">
            <div className="col-md-6">
              <div className="container">
                <span className="fade-in heading" style={{}}>
                  The global commerce platform, Unleash Your Online Business
                  Potential
                </span>

                <h1
                  className="fade-in heading"
                  style={{
                    display: "block",
                    margin: "0 auto",
                    maxWidth: "580px",
                    marginTop: "20px",
                    fontSize: 16,
                    fontWeight: "bold",
                    color: COLORS.gray,
                  }}
                >
                  Build your online store, sell your products, and ship your
                  orders all in one place
                </h1>
                <div className="text-center fade-in">
                  <button
                    onClick={() => {
                      navigate("/NewStore");
                    }}
                    className="btn btn-primary start-selling-btn my-3"
                    style={{
                      background: COLORS.primary,
                      color: "rgb(0, 0, 0)",
                      borderColor: "#FFC107",
                    }}
                  >
                    Start Selling Online Now!
                  </button>
                </div>
              </div>
            </div>
            <div className="col-6 d-flex justify-content-end fade-in image-container">
              <img src="https://i.ibb.co/jbgLx5n/main.png" alt="main" />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-lg-5">
        <span>
          <p
            className="text-center"
            style={{
              color: COLORS.success,
              fontWeight: "bold",
            }}
          >
            Build your online shop
          </p>
          <span
            style={{
              display: "block",
              margin: "0 auto",
              maxWidth: "600px",
              textAlign: "center",
              fontSize: 28,
              fontWeight: "bold",
            }}
          >
            Our platform allows you to create your online shop quickly and
            easily
          </span>
        </span>
      </div>

      <HomeLayout
        Heading={"Create Your Online Shop"}
        Text={
          "Build your online shop quickly and easily with our intuitive platform which allows you to customize to fit your brand and start selling no time"
        }
        ButtonText={"Learn More"}
        Image={"https://i.ibb.co/CKLYSj5/AppHost.png"}
        ContentSide="left"
      />
      <HomeLayout
        Heading={"Hassle-Free App Hosting"}
        Text={`We take care of it all for you, everything from app deployment to
          server maintenance, ensuring that your app is up and running
          smoothly at all times. Our app hosting services are included with
          every package.`}
        ButtonText={"Learn More"}
        Image={"https://i.ibb.co/CKLYSj5/AppHost.png"}
        ContentSide="right"
      />
      <HomeLayout
        ButtonText={"Learn More"}
        Heading={"Effortlessly Manage Your Business"}
        Image={"https://i.ibb.co/CKLYSj5/AppHost.png"}
        Text={`
        Our platform makes managing your online business a breeze. From
            order management to secure payment processing, our tools help you
            stay on top and focus on what matters most.
        `}
        ContentSide="left"
      />
    </div>
  );
}
