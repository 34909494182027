import React from "react";
import PrimaryButton from "../Atoms/PrimaryButton";
import TickIcon from "@mui/icons-material/Done";
import "../../Styles/PricingBoard.css";
import { useNavigate } from "react-router-dom";
export default function PricingBoard({
  Heading,
  Price,
  Description,
  Features,
}) {
  const navigate = useNavigate();
  return (
    <div className="pricing-board-container fade-in" style={{}}>
      <div className="text-center">
        <span className="pricing-board-heading">{Heading}</span>
        <br />
        <span
          style={{
            fontSize: "30px",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          {Price}
        </span>
        <br />
        <span
          style={{
            fontSize: "16px",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          {Description}
        </span>
      </div>
      <div className="my-4">
        <PrimaryButton
          onCLick={() => {
            navigate("/NewStore");
          }}
          ButtonText={"Get Store Now !"}
        />
      </div>

      {Features.map((feature) => {
        return (
          <div className="d-flex my-2">
            <div className="mx-2">
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  textAlign: "center",
                  border: "1px solid #3763F433",
                  backgroundColor: "#3763F433",
                  padding: "2px",
                  borderRadius: "10%",
                  marginRight: "5px",
                  alignContent: "center",
                }}
              >
                <TickIcon />
              </span>
            </div>
            <div>
              <span>{feature.Feature}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
}
