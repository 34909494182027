import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
export default function NotFound() {
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        alignItems: "center",
        fontFamily: "Red Hat Display",
      }}
    >
      <Helmet>
        <title>404: Page not found | Rebuilters</title>
      </Helmet>
      <h1 style={{ margin: "auto", textAlign: "center" }}>
        404: Page not found
        <br />
        <span>Check the URL in the address bar or return to the</span>
        <br />
        <a href="/">Rebuilters Home Page</a>
      </h1>
    </div>
  );
}
