import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDCUyYWQdIbWvkI3gdHKZ8iFf3dTCIrkFY",
  authDomain: "rebuilters.firebaseapp.com",
  projectId: "rebuilters",
  storageBucket: "rebuilters.appspot.com",
  messagingSenderId: "923864551963",
  appId: "1:923864551963:web:bc0380feaafc14a5199ebe",
  measurementId: "G-GJVPG65NWF",
};

firebase.initializeApp(firebaseConfig);
let auth = firebase.auth();
export { firebase, auth };
