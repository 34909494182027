import React, { useEffect, useState } from "react";
import PrimaryButton from "../../Components/Atoms/PrimaryButton";
import "../../Styles/Contact.css";
import { sendQuery } from "../../Api/Support/sendQuery";
import { Helmet } from "react-helmet";
export default function Contact() {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [query, setQuery] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = () => {
    setLoading(true);
    sendQuery({
      Name: name,
      Email: email,
      Query: query,
      Phone: null,
    })
      .then((res) => {
        setLoading(false);
        alert("Query Submitted Successfully");
      })
      .catch((err) => {
        setLoading(false);
        alert("Something went wrong");
      });
  };

  return (
    <div
      className="home-container"
      style={{
        paddingTop: "10vh",
      }}
    >
      <Helmet>
        <title>Contact Us | Rebuilters</title>
        <meta
          name="description"
          content="
          Rebuilters: Empowering small-scale brands to thrive. Unlock your business's potential with our tailored solutions. Contact us now!
          "
        ></meta>
      </Helmet>
      <div>
        <img
          src="https://i.ibb.co/VB4wqnW/DSC6832.jpg"
          alt="Contact Us"
          style={{
            width: "100%",
            height: "60vh",
            objectFit: "cover",
          }}
        />
      </div>

      <div
        style={{
          marginTop: "10vh",
        }}
        className="text-center"
      >
        <span
          style={{
            fontSize: "33px",
            fontWeight: "bold",
          }}
        >
          Contact Rebuilters Support
        </span>
        <br />
        <span
          style={{
            fontSize: "20px",
          }}
        >
          Enter your name and email address & query and we'll get back to you as
          soon as possible.
        </span>
      </div>
      <div
        className=" container"
        style={{
          marginTop: "10vh",
        }}
      >
        <div className="row">
          <div className="col-md-12">
            <div
              className="contact-input-container"
              style={{
                display: "block",

                justifyContent: "center",
                margin: "auto",
              }}
            >
              <input
                onChange={(e) => {
                  setName(e.target.value);
                }}
                type="text"
                className="form-control fw-bold"
                placeholder="Name"
                style={{
                  marginTop: "10px",

                  borderRadius: "10px",
                }}
              />
              <input
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                type="email"
                className="form-control fw-bold"
                placeholder="Email"
                style={{
                  marginTop: "10px",

                  borderRadius: "10px",
                }}
              />
              <textarea
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
                type="text"
                className="form-control fw-bold"
                placeholder="Query"
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",

                  borderRadius: "10px",
                  height: "200px",
                }}
              />
            </div>

            <div
              style={{
                width: "30%",

                paddingBottom: "10px",
                margin: "auto",
              }}
            >
              <PrimaryButton
                onCLick={handleSubmit}
                loading={loading}
                ButtonText={"Submit Query"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
