import React, { useEffect } from "react";
import { Container, Card } from "react-bootstrap";

const TermsAndConditions = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = "Terms and Conditions | Rebuilters";

    }, []);
   
  return (
    <div className="home-container">
      <div >
        <Container
          style={{
            paddingTop: "100px",
          }}
        >
          <Card>
            <Card.Body>
              <Card.Title className="fw-bold">Terms and Conditions</Card.Title>
              <Card.Text>
                These terms and conditions govern your use of the Rebuilters
                website and services (hereinafter referred to as "Services"). By
                accessing or using our Services, you agree to be bound by these
                terms and conditions. If you do not agree with any part of these
                terms and conditions, please do not use our Services.
              </Card.Text>
              <Card.Text>
                <strong>1. Intellectual Property</strong>
                <br />
                The content on the Rebuilters website, including but not limited
                to text, graphics, logos, images, and software, is the property
                of Rebuilters and is protected by applicable copyright laws. You
                may not reproduce, distribute, modify, transmit, or use the
                content of our website without prior written permission from
                Rebuilters.
              </Card.Text>
              <Card.Text>
                <strong>2. User Responsibilities</strong>
                <br />
                When using our Services, you agree to: - Provide accurate and
                up-to-date information during the registration process. - Use
                the Services in compliance with all applicable laws and
                regulations. - Not engage in any illegal or unauthorized
                activities. - Not disrupt or interfere with the security or
                integrity of our Services. - Not attempt to gain unauthorized
                access to any part of our Services or systems.
              </Card.Text>
              <Card.Text>
                <strong>3. Limitation of Liability</strong>
                <br />
                Rebuilters shall not be liable for any direct, indirect,
                incidental, special, consequential, or exemplary damages
                resulting from the use or inability to use our Services. This
                includes, but is not limited to, damages for loss of profits,
                data, or other intangible losses, even if Rebuilters has been
                advised of the possibility of such damages.
              </Card.Text>
              <Card.Text>
                <strong>4. Indemnification</strong>
                <br />
                You agree to indemnify and hold Rebuilters and its affiliates,
                officers, employees, and agents harmless from and against any
                claims, liabilities, damages, losses, and expenses, including,
                without limitation, reasonable legal and accounting fees,
                arising out of or in any way connected with your use of our
                Services or your violation of these terms and conditions.
              </Card.Text>
              <Card.Text>
                <strong>5. Changes to the Terms and Conditions</strong>
                <br />
                Rebuilters reserves the right to modify or replace these terms
                and conditions at any time. Any changes will be effective
                immediately upon posting the revised terms and conditions on our
                website. By continuing to use our Services after the posting of
                such changes, you agree to be bound by the modified terms and
                conditions.
              </Card.Text>
              <Card.Text>
                <strong>6. Governing Law</strong>
                <br />
                These terms and conditions shall be governed by and construed in
                accordance with the laws of [Your Jurisdiction]. Any dispute
                arising out of or related to these terms and conditions shall be
                subject to the exclusive jurisdiction of the courts in [Your
                Jurisdiction].
              </Card.Text>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </div>
  );
};

export default TermsAndConditions;
