import React, { useEffect } from "react";
import "../Styles/Services.css";
import { Helmet } from "react-helmet";
export default function Services() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  
  return (
    <div className="home-container">
      <Helmet>
        <title>Services | Rebuilters</title>
      </Helmet>
      <div style={{
        height: "100vh",
      }}>

      </div>
    </div>
  );
}
