import React, { useEffect } from "react";
import "../../Styles/ShowCaseBoard.css";
const ShowcaseBoard = ({ backgroundImage, heading, content, link }) => {
  return (
    <div
      className="ShowCaseBoardContainer"
      style={{
        backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)), url('${backgroundImage}')`,
      }}
    >
      <div className="ShowCaseBoardContentContainer">
        <span className="ShowCaseBoardHeading">{heading}</span>
        <span className="ShowCaseBoardContent">{content}</span>
        <span
          className="ShowCaseBoardLink"
          onClick={() => {
            window.open(link);
          }}
        >
          {link}
        </span>
      </div>
    </div>
  );
};

export default ShowcaseBoard;
