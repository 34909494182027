import React, { useEffect } from "react";
import { Container, Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="home-container">
      <Helmet>
        <title>About Us | Rebuilters</title>
        <meta
          name="description"
          content="
          Rebuilters: Empowering small-scale brands to thrive. Unlock your business's potential with our tailored solutions. Contact us now!
          "
        ></meta>
      </Helmet>
      <Container
        style={{
          paddingTop: "100px",
        }}
      >
        <Card>
          <Card.Body>
            <Card.Title className="fw-bold">About Us</Card.Title>
            <Card.Text>
              Rebuilters is a leading provider of comprehensive e-commerce
              solutions for small-scale brands. We specialize in helping
              businesses establish their online presence quickly and
              efficiently, without the need for extensive development work.
            </Card.Text>
            <Card.Text>
              Our mission is to empower entrepreneurs and small businesses by
              offering a complete package that includes website creation,
              payment processing, and shipping management. With our easy-to-use
              platform, businesses can quickly launch their online stores and
              start selling their products to a global audience.
            </Card.Text>
            <Card.Text>
              At Rebuilters, we understand the challenges that e-commerce
              businesses face, and we have tailored our services to address
              those needs. Our platform provides a user-friendly interface,
              allowing businesses to create and manage their online stores with
              ease. We offer a range of customizable templates that can be
              tailored to match each client's branding and requirements,
              ensuring a unique and professional online presence.
            </Card.Text>
            <Card.Text>
              One of the key features that set us apart is our integrated
              shipping management system. We understand the importance of
              reliable and efficient shipping for e-commerce businesses.
              Therefore, we provide a hassle-free shipping solution that takes
              care of everything from order fulfillment to tracking shipments
              and handling returns. Our platform supports various shipping
              options, including standard, express, and even same-day delivery
              in select areas, allowing businesses to provide a seamless
              customer experience.
            </Card.Text>
            <Card.Text>
              In addition to shipping, we also offer secure payment processing.
              Our platform integrates with trusted payment gateways, including
              UPI, credit/debit cards, and net banking, ensuring smooth and
              secure transactions for both businesses and customers. We
              prioritize the security of sensitive information and follow
              industry standards to ensure PCI compliance.
            </Card.Text>
            <Card.Text>
              At Rebuilters, we are committed to providing high-quality service
              and support to our clients. Our dedicated team is always available
              to assist businesses in setting up and managing their online
              stores. We offer ongoing maintenance and server costs as part of
              our comprehensive package, eliminating the technical burdens and
              allowing our clients to focus on their core business operations.
            </Card.Text>
            <Card.Text>
              Thank you for considering Rebuilters for your e-commerce needs. We
              are passionate about helping small-scale brands succeed in the
              digital marketplace, and we look forward to partnering with you on
              your journey to e-commerce success.
            </Card.Text>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default AboutUs;
