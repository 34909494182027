import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../../Utils/COLORS";
import "../../Styles/Navbar.css";
export default function Navbar() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const navigate = useNavigate();

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const closeNav = () => {
    if (isNavOpen) {
      setIsNavOpen(false);
    }
  };

  const navbarStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 100,
    background: "#ffffff",
  };

  const pageContentStyle = {
    marginTop: isNavOpen ? "64px" : 0,
  };

  const buttonStyle = {
    background: COLORS.primary,
    color: "rgb(0,0,0)",
    borderColor: "#FFC107",
  };

  return (
    <div
      style={{
        fontFamily: "Red Hat Display, sans-serif",
      }}
    >
      <nav
        className="navbar navbar-light navbar-expand-md py-3"
        style={navbarStyle}
      >
        <div className="container">
          <a className="navbar-brand d-flex align-items-center" href="/">
            <img
              src="https://i.ibb.co/xh9XmxR/Logo.png"
              width="34"
              height="34"
              alt=""
            />
            <span className="fw-bold pt-2"> ebuilters</span>
          </a>
          <button className="navbar-toggler" type="button" onClick={toggleNav}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${isNavOpen ? "show" : ""}`}
            id="navcol-1"
          >
            <ul className="navbar-nav mx-auto">
              <li className="nav-item">
                <a className="nav-link" href="/" onClick={closeNav}>
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/Services" onClick={closeNav}>
                  Services
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/Showcase" onClick={closeNav}>
                  Showcase
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/Pricing" onClick={closeNav}>
                  Pricing
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/Templates">
                  Templates
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/Contact" onClick={closeNav}>
                  Contacts
                </a>
              </li>
            </ul>
            {true ? (
              <a
                className="btn btn-primary shadow"
                role="button"
                href={
                  window.location.pathname === "/NewStore" ||
                  window.location.pathname === "/Register"
                    ? "https://admin.rebuilters.store"
                    : "/NewStore"
                }
                style={buttonStyle}
              >
                {window.location.pathname === "/NewStore" ||
                window.location.pathname === "/Register"
                  ? "Manage Your Online Store"
                  : "Launch Your Online Store"}
              </a>
            ) : null}
          </div>
        </div>
      </nav>
      <div className="page-content" style={pageContentStyle}>
        {/* Your page content */}
      </div>
    </div>
  );
}
