import React, { useEffect, useState } from "react";
import "../Styles/NewStore.css";
import { COLORS } from "../Utils/COLORS";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";
export default function NewStore() {
  const navigate = useNavigate();

  useEffect(() => {
    //add og image for social media
    const meta = document.createElement("meta");
    meta.name = "og:image";
    meta.content = "https://i.ibb.co/QKW0SHN/Hehe.png";
    document.getElementsByTagName("head")[0].appendChild(meta);
  }, []);

  const handleClick = () => {
    if (BrandName.length > 3) {
      navigate("/Register", {
        state: {
          BrandName: BrandName,
        },
      });
    } else {
      alert("Brand name must be at least 4 characters long");
    }
  };

  const updateBrandName = (e) => {
    //only letters , numbers and spaces and apostrophe allowed
    const regex = /^[a-zA-Z0-9' ]*$/;
    if (regex.test(e.target.value)) {
      setBrandName(e.target.value);
    }
  };
  const [BrandName, setBrandName] = useState("");
  const buttonStyle = {
    background: COLORS.primary,
    color: "rgb(0,0,0)",
    borderColor: "#FFC107",
  };
  return (
    <div className="new-store-background home-container">
      <Helmet>
        <title>
          Create Your Online Store with Rebuilters | Launch Your Business and
          Reach Customers Worldwide
        </title>
        <meta
          name="description"
          content=" 
          Launch Your Online Store within seconds with rebuilters,
          Create your online store, sell your products, and ship your orders all in one place , Build your online shop quickly and easily with our intuitive platform which allows you to customize to fit your brand and start selling no time
          , Also Partner with multiple shipping companies to deliver your products to your customers worldwide
          "
        />
        <meta
          name="keywords"
          content="online store, online shop, create online store, create online shop, sell online, sell online worldwide, sell online in egypt, sell online in middle east, sell online in africa, sell online in asia, sell online in europe, sell online in america, sell online in australia, sell online in north america, sell online in south america, sell online in asia, sell online in europe, sell online in africa, sell online in australia, sell online in egypt, sell online in middle east, sell online in north america, sell online in south america, sell online in asia, sell online in europe, sell online in africa, sell online in australia, sell online in egypt, sell online in middle east, sell online in north america, sell online in south america, sell online in asia, sell online in europe, sell online in africa, sell online in australia, sell online in egypt, sell online in middle east, sell online in north america, sell online in south america"
        />
      </Helmet>
      <div
        style={{
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div className="container">
          <div
            className="row"
            style={{
              paddingTop: "20vh",
            }}
          >
            <div
              style={{
                display: "block",
                paddingTop: "15vh",
              }}
              className="col-md-6"
            >
              <div
                style={{
                  display: "block",
                  margin: "0 auto",
                  maxWidth: "580px",
                }}
              >
                <h1 class="fw-bold">
                  Creating the Perfect Online Store for You and Your Customers
                </h1>
                <p class="my-3">
                  Providing you with the tools you need to create an exceptional
                  online shopping experience for your customers.
                </p>
                <form class="d-flex justify-content-center flex-wrap justify-content-md-start flex-lg-nowrap">
                  <div class="my-2 me-2">
                    <input
                      value={BrandName}
                      onChange={updateBrandName}
                      class="form-control border rounded-pill fw-bold"
                      type="text"
                      name="BrandName"
                      placeholder="Your Brand Name"
                    ></input>
                    <p></p>
                    <button
                      onClick={handleClick}
                      className="btn btn-primary shadow w-100"
                      style={buttonStyle}
                    >
                      Make Your Brand Online !
                    </button>
                  </div>
                  <p></p>
                </form>
              </div>
            </div>

            <div className="col-md-6 text-center">
              <img
                alt="vector"
                className="vector-image-newStore"
                src="https://i.ibb.co/CKLYSj5/AppHost.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
