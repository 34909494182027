import React, { useEffect } from "react";
import "../Styles/Pricing.css";
import PricingBoard from "../Components/Organism/PricingBoard";
import { PricingData } from "../Utils/Constant";
import { Helmet } from "react-helmet";

export default function Pricing() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className="home-container"
      style={{
        marginTop: "15vh",
      }}
    >
      <Helmet>
        <title>Pricing | Rebuilters</title>
        <meta
          name="description"
          content="  Choose Your Plan and Launch Your Dream Business Today! |  Our affordable pricing options are designed to fit any budget."
        />
      </Helmet>
      <div className="pricing-heading-container">
        <span className="pricing-heading">
          Choose Your Plan and Launch Your Dream Business Today!
        </span>
        <br />
        <span className="my-3">
          Our affordable pricing options are designed to fit any budget.
        </span>
      </div>
      <div className="container ">
        <div className="row">
          <div className="col-md-4 my-4 ">
            <PricingBoard
              Description={PricingData[0].Description}
              Features={PricingData[0].Features}
              Heading={PricingData[0].Heading}
              Price={PricingData[0].Price}
            />
          </div>
          <div className="col-md-4 my-4 ">
            <PricingBoard
              Description={PricingData[1].Description}
              Features={PricingData[1].Features}
              Heading={PricingData[1].Heading}
              Price={PricingData[1].Price}
            />
          </div>
          <div className="col-md-4 my-4">
            <PricingBoard
              Description={PricingData[2].Description}
              Features={PricingData[2].Features}
              Heading={PricingData[2].Heading}
              Price={PricingData[2].Price}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
