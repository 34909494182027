import axios from "axios";

const MODE_URL = {
  DEV: "http://localhost:4000",
  PROD: "https://api.rebuilters.store",
};

const instance = axios.create({
  baseURL: MODE_URL.PROD,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

export default instance;
