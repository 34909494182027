import React, { useEffect } from "react";
import { Container, Card } from "react-bootstrap";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Privacy Policy | Rebuilters";
  }, []);

  return (
    <div className="home-container">
      <div>
        <div
          style={{
            paddingTop: "100px",
          }}
        >
          <Container className="">
            <Card>
              <Card.Body>
                <Card.Title className="fw-bold">
                  Privacy Policy</Card.Title>
                <Card.Text>
                  This Privacy Policy governs the manner in which Rebuilters
                  collects, uses, maintains, and discloses information collected
                  from users (hereinafter referred to as "Users") of the
                  Rebuilters website and services (hereinafter referred to as
                  "Services"). This policy applies to the Rebuilters website and
                  all products and services offered by Rebuilters.
                </Card.Text>
                <Card.Text>
                  <strong>1. Information We Collect</strong>
                  <br />
                  We may collect personal identification information from Users
                  in various ways, including but not limited to when Users visit
                  our website, register on the website, place an order, fill out
                  a form, subscribe to the newsletter, respond to a survey, or
                  engage in other activities, services, features, or resources
                  we make available on our website. Users may be asked for their
                  name, email address, mailing address, phone number, and
                  payment details. We will collect personal identification
                  information from Users only if they voluntarily submit such
                  information to us. Users can always refuse to supply
                  personally identification information, except that it may
                  prevent them from engaging in certain website-related
                  activities.
                </Card.Text>
                <Card.Text>
                  <strong>2. How We Use Collected Information</strong>
                  <br />
                  Rebuilters may collect and use Users' personal information for
                  the following purposes: - To process orders: We may use the
                  information Users provide about themselves when placing an
                  order only to provide service to that order. We do not share
                  this information with outside parties except to the extent
                  necessary to provide the service. - To improve customer
                  service: Information Users provide helps us respond to
                  customer service requests and support needs more efficiently.
                  - To personalize user experience: We may use information in
                  the aggregate to understand how our Users as a group use the
                  services and resources provided on our website. - To send
                  periodic emails: We may use the email address to send Users
                  information and updates pertaining to their order. It may also
                  be used to respond to their inquiries, questions, and/or other
                  requests. If a User decides to opt-in to our mailing list,
                  they will receive emails that may include company news,
                  updates, related product or service information, etc. If at
                  any time the User would like to unsubscribe from receiving
                  future emails, we include detailed unsubscribe instructions at
                  the bottom of each email.
                </Card.Text>
                <Card.Text>
                  <strong>3. How We Protect Your Information</strong>
                  <br />
                  We adopt appropriate data collection, storage, and processing
                  practices and security measures to protect against
                  unauthorized access, alteration, disclosure, or destruction of
                  your personal information, username, password, transaction
                  information, and data stored on our website.
                </Card.Text>
                <Card.Text>
                  <strong>4. Sharing Your Personal Information</strong>
                  <br />
                  We do not sell, trade, or rent Users' personal identification
                  information to others. We may share generic aggregated
                  demographic information not linked to any personal
                  identification information regarding visitors and users with
                  our business partners, trusted affiliates, and advertisers for
                  the purposes outlined above.
                </Card.Text>
                <Card.Text>
                  <strong>5. Compliance with Legal Requirements</strong>
                  <br />
                  We may disclose personal information if required by law or in
                  response to valid requests by public authorities (e.g., a
                  court or government agency).
                </Card.Text>
                <Card.Text>
                  <strong>6. Changes to This Privacy Policy</strong>
                  <br />
                  Rebuilters has the discretion to update this privacy policy at
                  any time. When we do, we will revise the updated date at the
                  bottom of this page. We encourage Users to frequently check
                  this page for any changes to stay informed about how we are
                  helping to protect the personal information we collect. You
                  acknowledge and agree that it is your responsibility to review
                  this privacy policy periodically and become aware of
                  modifications.
                </Card.Text>
                <Card.Text>
                  <strong>7. Your Acceptance of These Terms</strong>
                  <br />
                  By using our website and services, you signify your acceptance
                  of this policy. If you do not agree to this policy, please do
                  not use our website or services. Your continued use of the
                  website following the posting of changes to this policy will
                  be deemed your acceptance of those changes.
                </Card.Text>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
