import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ShowcaseBoard from "../Components/Organism/ShowCaseBoard";

export default function Showcase() {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const Data = [
    {
      backgroundImage:
        "https://i.ibb.co/TM3n0B3/00-F232-B2-8-C26-478-B-9-A3-D-E5-A305-F31-FE1.jpg",
      title: "Aman's Attire",
      description: `Aman, a college student with a passion for gothic fashion, stands out from his peers with his striking and unique attire. Draped in a dark palette of blacks, purples, and burgundies, he exudes an air of mystery and individuality. His wardrobe boasts a collection of long coats, leather jackets, and flowing capes, adorned with intricate details like lace, velvet, chains, and studs. Aman completes his ensemble with silver or pewter jewelry, featuring gothic symbols, and pairs his attire with black leather boots that add a touch of rebellion. With a versatile style that incorporates elements from various subcultures, Aman's gothic fashion showcases his creativity, making a bold statement on his journey through college.`,
      link: "https://amansattire.rebuilters.store",
    },
    {
      backgroundImage: "https://i.ibb.co/fY3Nzyx/DSC6832.jpg",
      title: "MystiqueCarry",
      description: `Vedika, the proprietor of MystiqueCarry, is a dedicated purveyor of exquisite tote bags. Her brand showcases a fusion of elegance and practicality, offering a diverse range of tote bags designed to elevate both style and functionality. Each bag is meticulously crafted with a keen attention to detail, using high-quality materials that exude luxury and durability. Vedika's tote bags seamlessly blend contemporary fashion trends with timeless sophistication, featuring tasteful color palettes, sleek designs, and thoughtful compartments for organization. Whether it's for everyday use, work, or special occasions, Vedika's tote bags are the perfect companion, embodying a sense of grace and sophistication while offering ample space to carry essentials. With MystiqueCarry, Vedika aims to redefine the concept of a tote bag, delivering a blend of elegance and practicality that complements the modern lifestyle.`,
      link: "https://mystiquecarry.rebuilters.store",
    },
  ];

  return (
    <div style={{ marginTop: "70px" }}>
      <Helmet>
        <title>Explore the Showcase of our work | Rebuilters</title>
        <meta
          name="description"
          content="Explore the showcase of our work. We have worked with many clients and helped them to grow their business. We have worked with many clients and helped them to grow their business."
        />
      </Helmet>
      {Data.map((item, index) => {
        const translateY = scrollPosition - index * 500;
        const opacity = 1 - Math.abs(translateY) / 500;

        return (
          <div key={index} className="parallax-container">
            <ShowcaseBoard
              link={item.link}
              backgroundImage={item.backgroundImage}
              heading={item.title}
              content={item.description}
            />
          </div>
        );
      })}
    </div>
  );
}
