import React, { useEffect, useState } from "react";
import "../Styles/Register.css";
import { useLocation } from "react-router";
import { COLORS } from "../Utils/COLORS";

import AppleLogin from "react-apple-login";
import GoogleIcon from "@mui/icons-material/Google";
import { firebase, auth } from "../Utils/firebase";

import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";

import IconButton from "../Components/Atoms/IconButton";
// import { createNewBrand } from "../Api/Auth/createNewBrand.";
import Lottie from "react-lottie";
import { Helmet } from "react-helmet";

export default function Register() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const googleProvider = new GoogleAuthProvider();
  const [brandCreationProcess, setBrandCreationProcess] = useState(false);
  const [MobileNumber, setMobileNumber] = useState("");
  const [Loading, setLoading] = useState(false);
  const [show, setshow] = useState(false);
  const [final, setfinal] = useState(null);
  const [OTP, setOTP] = useState("");

  const updateMobileNumber = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setMobileNumber(e.target.value);
    }
  };

  const verifyOTP = () => {
    // if (OTP.length !== 6) {
    //   alert("Please enter a valid OTP");
    //   return;
    // }
    // setLoading(true);
    // final
    //   .confirm(OTP)
    //   .then((result) => {
    //     console.log(result);
    //     setBrandCreationProcess(true);
    //     createNewBrand({
    //       BrandName: state.BrandName,
    //       MobileNumber,
    //     })
    //       .then((res) => {
    //         console.log(res);
    //         const brandId = res.BrandId;
    //         const accessToken = res.result.AccessToken;
    //         localStorage.setItem("BrandId", brandId);
    //         localStorage.setItem("AccessToken", accessToken);
    //         window.location.href = "https://admin.rebuilters.store/";
    //       })
    //       .catch((err) => {
    //         alert(err.message);
    //         setBrandCreationProcess(false);
    //       })
    //       .finally(() => {
    //         setBrandCreationProcess(false);
    //       });

    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     alert("The OTP you entered is incorrect");
    //     setLoading(false);
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //   });
  };

  const updateOTP = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setOTP(e.target.value);
    }
  };
  const { state } = useLocation();
  if (state == null) {
    window.location.href = "/NewStore";
  }
  const buttonStyle = {
    background: COLORS.primary,
    color: "rgb(0,0,0)",
    borderColor: "#FFC107",
    borderRadius: "0px",
    width: "100%",
    marginTop: "20px",
    height: "50px",
  };
  const dividerlineStyle = {
    width: "100%",
    height: "1px",
    background: COLORS.gray,
    marginTop: "20px",
  };

  const sendOTP = () => {
    if (MobileNumber.length === 10) {
      setLoading(true);
      let verify = new firebase.auth.RecaptchaVerifier("recaptcha-container");
      auth
        .signInWithPhoneNumber(`+91` + MobileNumber, verify)
        .then((result) => {
          setfinal(result);
          setLoading(false);
          setshow(true);
        });
    } else {
      alert("Please enter a valid mobile number");
    }
  };

  const signInWithGoogle = async () => {
    try {
      const res = await signInWithPopup(auth, googleProvider);
      const user = res.user;
      console.log(user);
      setBrandCreationProcess(true);

      // createNewBrand({
      //   BrandName: state.BrandName,
      //   Email: user.email,
      // })
      //   .then((res) => {
      //     console.log(res);
      //     const brandId = res.BrandId;
      //     const accessToken = res.result.AccessToken;
      //     localStorage.setItem("BrandId", brandId);
      //     localStorage.setItem("AccessToken", accessToken);
      //     window.location.href = "https://admin.rebuilters.store/";
      //   })
      //   .catch((err) => {
      //     alert(err.message);
      //     setBrandCreationProcess(false);
      //   })
      //   .finally(() => {
      //     setBrandCreationProcess(false);
      //   });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="register-container">
      <Helmet>
        <title>Register Now | Rebuilters</title>
        <meta
          name="description"
          content="
          Rebuilters: Empowering small-scale brands to thrive. Unlock your business's potential with our tailored solutions. Register now!
          "
        ></meta>
      </Helmet>
      <div className="register-background">
        {!brandCreationProcess ? (
          <div className="kind-of-container">
            <div
              className="register-main-container"
              style={{
                padding: "20px 20px 20px 20px",
                background: "white",
              }}
            >
              <span
                style={{
                  fontSize: "26px",
                }}
              >
                Welcome ,
              </span>
              <br />
              <span
                style={{
                  fontSize: "26px",
                  fontWeight: "bold",
                }}
              >
                {state.BrandName}
              </span>

              <div
                style={{
                  paddingTop: "20px",
                }}
              >
                <span
                  style={{
                    fontSize: "15px",

                    color: "gray",
                  }}
                >
                  Mobile number
                </span>
              </div>

              <br />
              <input
                disabled={final}
                value={MobileNumber}
                onChange={updateMobileNumber}
                maxLength={10}
                class="form-control border  fw-bold"
                type="tel"
                name="PhoneNumber"
                placeholder="Enter Your Mobile number"
                style={{
                  borderRadius: "2px",
                  width: "350px",
                }}
              ></input>
              {final && (
                <input
                  disabled={Loading}
                  value={OTP}
                  onChange={updateOTP}
                  maxLength={6}
                  class="form-control border  fw-bold"
                  type="tel"
                  name="OTP"
                  placeholder="Enter 6 digit OTP sent to your mobile number"
                  style={{
                    borderRadius: "2px",
                    width: "350px",
                    marginTop: "20px",
                  }}
                ></input>
              )}

              <button
                className="btn btn-primary shadow"
                role="button"
                style={buttonStyle}
                onClick={final ? verifyOTP : sendOTP}
                about="Send OTP"
                name="Send OTP"
              >
                {Loading ? (
                  <div class="spinner-border text-light" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                ) : final ? (
                  "Verify OTP"
                ) : (
                  "Send OTP"
                )}
              </button>
              <div
                style={{
                  visibility: !show ? "visible" : "hidden",
                }}
                id="recaptcha-container"
              ></div>

              <div className="row  justify-content-center">
                <div className="col-5">
                  <div style={dividerlineStyle}></div>
                </div>
                <div className="col-1 text-center pt-2">
                  <div>or</div>
                </div>
                <div className="col-5">
                  <div style={dividerlineStyle}></div>
                </div>
              </div>
              <div></div>

              <IconButton
                onClick={signInWithGoogle}
                Icon={<GoogleIcon />}
                background={COLORS.google}
                color={COLORS.white}
                text={"Continue With Google"}
              />

              <div id="apple-id-signin" style={{}}>
                <AppleLogin
                  render={(
                    renderProps //Custom Apple Sign in Button
                  ) => (
                    <IconButton
                      onClick={renderProps.onClick}
                      background={COLORS.black}
                      color={COLORS.white}
                      text={"Continue With Apple"}
                    />
                  )}
                  clientId="fsfdfd"
                  redirectURI=""
                />
              </div>

              <div
                style={{
                  paddingTop: "20px",
                  width: "350px",
                }}
              >
                <span
                  style={{
                    fontSize: "15px",

                    color: "gray",
                    display: "block",
                  }}
                >
                  By proceeding, you agree to the Terms and Conditions and
                  Privacy Policy
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div className="register-main-container-loading container row">
            <div className="col-md-6 text-center">
              <Lottie
                className="Store-Making-Animation"
                style={{
                  height: "40vh",
                  width: "40vh",
                }}
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: require("../assets/Animations/Setup.json"),
                }}
              />
            </div>
            <div className="col-md-6 align-self-center">
              <br />
              <span
                className="register-tags"
                style={{
                  fontWeight: "bold",
                }}
              >
                Your Online Store is{"  "}
                <span
                  style={{
                    color: COLORS.google,
                  }}
                >
                  being created
                </span>{" "}
                . This process may take a few seconds . Thank you for your
                patience
              </span>
              <br />
              <span
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                }}
              ></span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
