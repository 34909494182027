import React from "react";
import { COLORS } from "../../Utils/COLORS";

export default function HomeLayout({
  Image,
  Text,
  ButtonText,
  Heading,
  ContentSide = "right",
}) {
  //Either Content Side Should be left or right
  const buttonStyle = {
    background: COLORS.primary,
    color: "rgb(0,0,0)",
    borderColor: "#FFC107",
  };
  return (
    <div
      className="row"
      style={{
        marginTop: "100px",
        marginLeft: "10px",
      }}
    >
      {ContentSide === "left" ? (
        <>
          <div className="col-md-6">
            <span
              style={{
                display: "block",
                margin: "0 auto",
                maxWidth: "480px",

                fontSize: 24,
                fontWeight: "bold",
              }}
            >
              {Heading}
            </span>

            <span
              style={{
                display: "block",
                margin: "0 auto",
                maxWidth: "480px",
                marginTop: "20px",
                fontSize: 16,
                fontWeight: "bold",
                color: COLORS.gray,
              }}
            >
              {Text}
            </span>
            <span
              style={{
                display: "block",
                margin: "0 auto",
                maxWidth: "480px",
                marginTop: "20px",
                fontSize: 16,
                fontWeight: "bold",
                color: COLORS.gray,
              }}
            >
              <a
                className="btn btn-primary shadow"
                role="button"
                onClick={() => {}}
                style={buttonStyle}
              >
                {" "}
                {ButtonText}
              </a>
            </span>
          </div>

          <div className="col-md-6 text-center vector-image">
            <img src={Image} width={"400px"} height={"400px"} alt="AppHost" />
          </div>
        </>
      ) : (
        <>
          <div className="col-md-6 text-center vector-image">
            <img src={Image} width={"400px"} height={"400px"} alt="AppHost" />
          </div>
          <div className="col-md-6">
            <span
              style={{
                display: "block",
                margin: "0 auto",
                maxWidth: "480px",

                fontSize: 24,
                fontWeight: "bold",
              }}
            >
              {Heading}
            </span>

            <span
              style={{
                display: "block",
                margin: "0 auto",
                maxWidth: "480px",
                marginTop: "20px",
                fontSize: 16,
                fontWeight: "bold",
                color: COLORS.gray,
              }}
            >
              {Text}
            </span>
            <span
              style={{
                display: "block",
                margin: "0 auto",
                maxWidth: "480px",
                marginTop: "20px",
                fontSize: 16,
                fontWeight: "bold",
                color: COLORS.gray,
              }}
            >
              <a
                className="btn btn-primary shadow"
                role="button"
                onClick={() => {}}
                style={buttonStyle}
              >
                {" "}
                {ButtonText}
              </a>
            </span>
          </div>
        </>
      )}
    </div>
  );
}
