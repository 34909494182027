import React from "react";
import GoogleIcon from "@mui/icons-material/Google";
import AppleIcon from "@mui/icons-material/Apple";
import { COLORS } from "../../Utils/COLORS";
import "../../Styles/Button.css";
export default function IconButton({
  onClick,

  background,
  color,
  text,
  Icon,
}) {
  return (
    <div className="row justify-content-center">
      <span
        onClick={onClick}
        className=" button-main"
        style={{
          fontSize: "15px",
          color: color ? color : "gray",
          fontWeight: "bold",
          borderColor: background ? background : "white",
          display: "block",
          margin: "2px ",
          textAlign: "center",
          width: "90%",
          marginTop: "20px",
          padding: "10px 10px 10px 10px",
          borderRadius: "2px",
          border: "1px solid gray",
          backgroundColor: background ? background : "white",
          alignItems: "center",
        }}
      >
        <span className="pb-2 pl-3">
          {Icon ? Icon : <AppleIcon style={{ color: color }} />}
        </span>{" "}
        {text}
      </span>
    </div>
  );
}
