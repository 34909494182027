import instance from "..";
import { SUPPORT_ENDPOINTS } from "../endpoints";

export function sendQuery({ Query, Name, Email, Phone }) {
  return new Promise((resolve, reject) => {
    instance
      .post(SUPPORT_ENDPOINTS.SEND_QUERY, {
        Query,
        Name,
        Email,
        Phone,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
        console.log(err);
      });
  });
}
