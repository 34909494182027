import React from "react";
import { useNavigate } from "react-router";
import { COLORS } from "../../Utils/COLORS";
import "../../Styles/Footer.css";
export default function Footer() {
  const navigate = useNavigate();
  return (
    <div
      className="row  py-5 home-container bg-light justify-content-center"
      style={{
        borderTop: `0.1px solid ${COLORS.primary}`,
      }}
    >
      <div
        className="col-3 justify-content-center"
        style={{
          display: "block",
          width: "200px",
        }}
      >
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          Rebuilters
        </span>
        <br />

        <p
          onClick={() => {
            navigate("/legal/PrivacyPolicy");
          }}
          className="my-2 pointer-event footer-link"
        >
          Privacy Policy
        </p>
        <p
          onClick={() => {
            navigate("/legal/Terms&Conditions");
          }}
          className="my-2 pointer-event footer-link"
        >
          Terms & Conditions
        </p>
      </div>

      <div
        className="col-3 justify-content-center"
        style={{
          display: "block",
          width: "200px",
        }}
      >
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          Support
        </span>
        <br />

        <p
          onClick={() => {
         
          }}
          className="my-2 pointer-event footer-link"
        >
          Contact
        </p>
        <p
          onClick={() => {
        
          }}
          className="my-2 pointer-event footer-link"
        >
          Live Support
        </p>
      </div>
      <div
        className="col-3 justify-content-center"
        style={{
          display: "block",
          width: "200px",
        }}
      >
        <span
          style={{
            fontWeight: "bold",
          }}
        >
          Info
        </span>
        <br />

        <p
          className="footer-link my-2"
          onClick={() => {
            navigate("/Info/AboutUs");
          }}
        >
          About Us
        </p>
        <p
          onClick={() => {
            navigate("/legal/Terms&Conditions");
          }}
          className="my-2 pointer-event footer-link"
        >
          Vision
        </p>
      </div>
    </div>
  );
}
