import Navbar from "./Components/Molecules/Navbar";
import Home from "./Routes/Home";
import "./App.css";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import NewStore from "./Routes/NewStore";
import Register from "./Routes/Register";
import PrivacyPolicy from "./Routes/Legal/PrivacyPolicy";
import TermsAndConditions from "./Routes/Legal/TermsAndCondition";
import Footer from "./Components/Molecules/Footer";
import AboutUs from "./Routes/Info/AboutUs";
import Pricing from "./Routes/Pricing";
import Showcase from "./Routes/Showcase";
import { ParallaxProvider } from "react-scroll-parallax";
import Contact from "./Routes/Info/Contact";
import NotFound from "./Routes/NotFound";
import Services from "./Routes/Services";
import Templates from "./Routes/Templates";
import { Analytics } from "@vercel/analytics/react"
function App() {
  return (
    <ParallaxProvider>
       <Analytics />
      <BrowserRouter>
        <Navbar />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/NewStore" element={<NewStore />} />
          <Route path="/Info/AboutUs" element={<AboutUs />} />
          <Route path="/Register" element={<Register />} />
          <Route path="/Templates" element={<Templates />} />
          <Route path="/Pricing" element={<Pricing />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Showcase" element={<Showcase />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/legal/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route
            path="/legal/Terms&Conditions"
            element={<TermsAndConditions />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>

        <Footer />
      </BrowserRouter>
    </ParallaxProvider>
  );
}

export default App;
