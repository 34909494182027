export const COLORS = {
    primary: "#ffc107",
    primaryDisabled: "#E5BC4180",
    secondary: "#6c757d",
    success: "#00B779",
    danger: "#DF2033",
    warning: "#ffc107",
    info: "#17a2b8",
    white: "#ffffff",
    light: "#f8f9fa",
    dark: "#343a40",
    white: "#fff",
    black: "#000",
    gray: "#4E5D78",
    google: "#4285F4",
  };
  