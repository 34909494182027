export const PricingData = [
    {
      Heading: "Starter",
      Price: "â¹0",
      Description: "Essential E-Commerce Package",
      Features: [
        {
          Feature: "Domain name provided by us (e.g., www.clothstore.rebuilters.store)",
        },
        {
          Feature: "Complete end-to-end shipping management (pickup to delivery)",
        },
        {
          Feature: "Fully functional online e-commerce store with integrated shopping cart and payment gateway",
        },
        {
          Feature: "Web hosting and maintenance included",
        },
        {
          Feature: "Responsive and mobile-friendly website design",
        },
        {
          Feature: "Admin panel to efficiently manage sales, orders, and other website aspects",
        },
        {
          Feature: "Instant activation of your website",
        },
      ],
    },
    {
      Heading: "Pro",
      Price: "â¹499",
      Description: "Accelerate Your E-Commerce Success",
      Features: [
        {
          Feature: "All features included in the Starter package",
        },
        {
          Feature: "Add your custom domain to the site (multiple domains supported)",
        },
        {
          Feature: "WhatsApp and email updates for orders and delivery for both customers and you",
        },
        {
          Feature: "Ability to add multiple warehouses",
        },
        {
          Feature: "Cash on Delivery available for your customers",
        },
        {
          Feature: "Return/replace orders with refunds managed by us",
        },
        {
          Feature: "Automatic invoice generation and sending to the customer",
        },
        {
          Feature: "Delivery labels for your orders (soft copy)",
        },
        {
          Feature: "Option to add discount coupons for your customers",
        },
        {
          Feature: "Abandoned cart updates for your customers via email and WhatsApp",
        },
      ],
    },
    {
      Heading: "Enterprise",
      Price: "â¹1499",
      Description: "Premium E-Commerce Suite",
      Features: [
        {
          Feature: "All features included in the Starter and Pro packages",
        },
        {
          Feature: "Android App for your store (APK file)",
        },
        {
          Feature: "iOS App for your store",
        },
        {
          Feature: "Ability to customize app layout and design using our templates",
        },
        {
          Feature: "Hosting of your mobile app on our play console and Apple Store",
        },
        {
          Feature: "Live chat support for your customers to connect with our/your team",
        },
        {
          Feature: "Become an elite member of Rebuilters",
        },
      ],
    },
  ];
  