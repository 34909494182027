import React from "react";
import { COLORS } from "../../Utils/COLORS";

export default function PrimaryButton({ ButtonText, onCLick }) {
  const buttonStyle = {
    background: COLORS.primary,
    color: "rgb(0,0,0)",
    borderColor: COLORS.primary,
    width: "100%",
  };
  return (
    <a
      className="btn btn-primary shadow"
      role="button"
      onClick={onCLick}
      style={buttonStyle}
    >
      {ButtonText}
    </a>
  );
}
